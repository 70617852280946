import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { styled, useTheme } from "@mui/material/styles";
import logo from "../images/1.svg";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import header from "../styles/Header.module.css";
import Link from "next/link";
import { useCookies } from "react-cookie";
import { Typography } from "@mui/material";

function Header({ logout, setLogout }) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "price_mortgage",
    "price_deposit",
    "state",
    "suburb_id",
    "type",
    "Transportation",
    "Type_of_schools",
    "Level",
    "Gender",
    "agreement",
    "login",
    "phone",
    "phonCode",
    "initialPath",
  ]);
  const [token, setToken] = React.useState(cookies.access_token);
  const Router = useRouter();

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const LogOutButton = styled(Button)(({ theme }) => ({
    color: "var(--dark-color)",
    fontSize: "15px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#054ef956",
    },
  }));

  const [loginButton, setLoginButton] = useState(cookies.login);
  useEffect(() => {
    setLoginButton(cookies.login);

    if (cookies.access_token) {
      setLogout(true);
    } else {
      setLogout(false);
    }
  }, [cookies.login]);

  const handleLogout = () => {
    removeCookie("access_token", { path: "/" });
    removeCookie("price_mortgage");
    removeCookie("price_deposit");
    removeCookie("state");
    removeCookie("suburb_id");
    removeCookie("type");
    removeCookie("Transportation");
    removeCookie("Type_of_schools");
    removeCookie("Level");
    removeCookie("Gender");
    removeCookie("agreement");
    removeCookie("login");
    removeCookie("phone");
    removeCookie("phoneCode");
    removeCookie("initialPath");
    localStorage.removeItem("suburb_reloaded");
    setTimeout(() => {
      window.location.replace("/");
    }, 30);
  };

  const handleLogin = () => {
    const currentPath = window.location.pathname;
    setCookie("initialPath", `${currentPath}`, { maxAge: 1800 });
    Router.push("/login/");
    // window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  let menuLocation;

  const getList = () => (
    <div style={{ width: 350 }} onClick={() => setOpen(false)}>
      <List>{/* List items */}</List>
    </div>
  );

  return (
    <div>
      <header className={header.header}>
        <div
          style={{ display: "flex", alignItems: "center", padding: "0 10px" }}
        >
          {menuLocation}
          <Link href="/" underline="hover">
            <Image
              src={logo}
              className={header.header - logo}
              alt="logo"
              width={185}
            />
          </Link>

          <a href="https://abodefinder.com.au/blog/">
            <LogOutButton sx={{ margin: "15px" }}>Blog</LogOutButton>
          </a>
        </div>
        {!cookies.access_token ? (
          // cookies.isLoggedIn !== "true"
          <LogOutButton onClick={handleLogin} variant="text">
            Log in
          </LogOutButton>
        ) : (
          <LogOutButton onClick={handleLogout} variant="text">
            Log out
          </LogOutButton>
        )}
        <Drawer open={open} anchor={"left"} onClose={() => setOpen(false)}>
          {/* Drawer content */}
        </Drawer>
      </header>
    </div>
  );
}

export default Header;
