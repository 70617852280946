import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Header from "../component/header";
import "../styles/globals.css";
import NextNProgress from "nextjs-progressbar";
import { useCookies } from "react-cookie";

export default function App({ Component, pageProps }) {
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [logout, setLogout] = React.useState(false);
  const router = useRouter();

  useEffect(() => {
    if (cookies.access_token) {
      setLogout(true);
    } else {
      setLogout(false);
      localStorage.removeItem("suburb_reloaded");
    }
    const handleRouteChange = (url) => {
      window.dataLayer.push({
        event: "pageview",
        pagePath: url,
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Header logout={logout} setLogout={setLogout} />
      <NextNProgress
        color="var(--primary-color)"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
      <Component {...pageProps} />
    </>
  );
}
